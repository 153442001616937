<template>
  <div class="hello">
      <div class="t1">
          <div class="t2"></div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.t1 {
    position: fixed;
    bottom: 10%;
    left: 0;
    width: 500px;
    height: 100px;
    background: pink;
    overflow-x: scroll;
}
.t2 {
    width: 800px;
    height: 100px;
    background: green;
}
</style>
